var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DraftsContainer',{attrs:{"type":_vm.type},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var drafts = ref.drafts;
var status = ref.status;
return [_c('SkeletonTransition',{attrs:{"ready":status === _vm.Progress.COMPLETE},scopedSlots:_vm._u([{key:"default",fn:function(){return [(drafts && drafts.length)?_c('Table',{attrs:{"itemKey":"id","items":drafts,"clickable":""},on:{"click":function (item) { return _vm.$router.push({ name: '/selfservice/drafts/:id', params: { id: item.id } }); }}},[_c('TableColumn',{attrs:{"header":"Navn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Oprettet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('Time',{attrs:{"datetime":item.edited}})]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Redigeret"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('Time',{attrs:{"datetime":item.edited,"showTime":""}})]}}],null,true)}),_c('TableColumn',{attrs:{"header":"erp"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.system_name)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Arbejdskort"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.worksheet_number)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Bil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('Vehicles',{attrs:{"data":item,"index":0}})]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Beløb","format":_vm.ColumnFormat.PRICE},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_amount)+" ")]}}],null,true)})],1):_c('p',[_vm._v("Der blev ikke fundet nogen "+_vm._s(_vm.$t('draft.type.' + _vm.type).toLowerCase()))])]},proxy:true},{key:"skeleton",fn:function(){return [_c('TableSkeleton',{attrs:{"rows":5}},[_c('SkeletonGraphic',{attrs:{"width":"80px","height":"20px"}}),_c('SkeletonGraphic',{attrs:{"width":"120px","height":"20px"}}),_c('SkeletonGraphic',{attrs:{"width":"50px","height":"20px"}}),_c('SkeletonGraphic',{attrs:{"width":"50px","height":"20px"}}),_c('SkeletonGraphic',{attrs:{"width":"70px","height":"20px"}}),_c('ButtonSkeleton')],1)]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
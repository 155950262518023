import { getDrafts } from '@services/thansen/drafts';

import Progress from '@types/Progress';

export default {
  name: 'DraftsContainer',
  data() {
    return {
      drafts: null,
      status: '',
      error: '',
    };
  },
  props: {
    type: {
      type: [String, Number],
      required: true,
    },
    preload: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  created() {
    if (this.preload) {
      this.fetchDrafts();
    }
  },
  methods: {
    fetchDrafts(type = this.type) {
      this.status = Progress.WORKING;
      getDrafts(type)
        .then(drafts => {
          this.drafts = drafts;
          this.status = Progress.COMPLETE;
        })
        .catch(error => {
          this.status = Progress.ERROR;
        });
    },
  },

  render() {
    return this.$scopedSlots.default({
      drafts: this.drafts,
      status: this.status,
      fetchDrafts: this.fetchDrafts,
    });
  },
  provide() {
    return { draftsContainer: this };
  },
};

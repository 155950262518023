<template>
  <ul class="VehicleList">
    <li v-for="vehicle in data.vehicles" :key="vehicle.id">
      <a :href="vehicle.url" :title="vehicle.description" class="VehicleList__name">{{
        vehicle.description
      }}</a>
      <div class="VehicleList__details">
        <b>{{ vehicle.license_plate }}</b>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'VehiclesTemplate',

  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.VehicleList {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  margin: 0;
  .VehicleList__details {
    font-weight: normal;
  }
}
</style>

<template>
  <DraftsContainer v-slot="{ drafts, status }" :type="type">
    <SkeletonTransition :ready="status === Progress.COMPLETE">
      <template #default>
        <Table
          v-if="drafts && drafts.length"
          itemKey="id"
          :items="drafts"
          clickable
          @click="
            item => $router.push({ name: '/selfservice/drafts/:id', params: { id: item.id } })
          "
        >
          <TableColumn v-slot="{ item }" header="Navn">
            {{ item.description }}
          </TableColumn>
          <TableColumn v-slot="{ item }" header="Oprettet">
            <Time :datetime="item.edited" />
          </TableColumn>
          <TableColumn v-slot="{ item }" header="Redigeret">
            <Time :datetime="item.edited" showTime />
          </TableColumn>
          <TableColumn v-slot="{ item }" header="erp">
            {{ item.system_name }}
          </TableColumn>
          <TableColumn v-slot="{ item }" header="Arbejdskort">
            {{ item.worksheet_number }}
          </TableColumn>
          <TableColumn v-slot="{ item }" header="Bil">
            <Vehicles :data="item" :index="0" />
          </TableColumn>
          <TableColumn v-slot="{ item }" header="Beløb" :format="ColumnFormat.PRICE">
            {{ item.total_amount }}
          </TableColumn>
        </Table>
        <p v-else>Der blev ikke fundet nogen {{ $t('draft.type.' + type).toLowerCase() }}</p>
      </template>
      <template #skeleton>
        <TableSkeleton :rows="5">
          <SkeletonGraphic width="80px" height="20px" />
          <SkeletonGraphic width="120px" height="20px" />
          <SkeletonGraphic width="50px" height="20px" />
          <SkeletonGraphic width="50px" height="20px" />
          <SkeletonGraphic width="70px" height="20px" />
          <ButtonSkeleton />
        </TableSkeleton>
      </template>
    </SkeletonTransition>
  </DraftsContainer>
</template>

<script>
import DraftsContainer from '../../containers/DraftsContainer';
import Progress from '@types/Progress';
import { Table, TableColumn, ColumnFormat } from '@components/Table';
import Vehicles from './components/Vehicles';
import Time from '@components/Time';

import {
  SkeletonTransition,
  TableSkeleton,
  SkeletonGraphic,
  ButtonSkeleton,
} from '@components/Skeleton';

export default {
  name: 'DraftsTable',

  components: {
    Table,
    TableColumn,
    DraftsContainer,
    SkeletonTransition,
    TableSkeleton,
    SkeletonGraphic,
    ButtonSkeleton,
    Vehicles,
    Time,
  },

  enums: {
    ColumnFormat,
    Progress,
  },

  props: {
    // Type is beging used for the store as a string prop.
    // When getting new rmas we will check for the prop. If the type
    // is the same, then we'll set the status to UPDATING.
    // Otherwise the status will be set to LOADING
    //
    // This will  have an impact on how content is shown or hidden
    // while our loading spinner is active
    //
    type: {
      required: true,
      type: [String, Number],
    },
  },
};
</script>

<template>
  <div>
    <SelfservicePageLayout>
      <template #controls>
        <!--<Button @click="createNew"><i class="far fa-plus"></i> Opret ny ordrekladde</Button>-->
      </template>
      <template #default>
        <p>
          Ordrekladden er en service for dig som bestiller ofte. Her har du mulighed for at oprette
          en eller flere ordrekladder til brug ved bestilling. Du kan tilføje et navn/notat på hver
          enkelt oprettet ordrekladde og rette i den, så tit du vil!
        </p>
        <br />

        <h2>Tilbud</h2>
        <DraftsTable :type="DraftTypes.QUOTE" />
      </template>
    </SelfservicePageLayout>
  </div>
</template>

<script>
import DraftTypes from '@types/DraftTypes';

import DraftsTable from './components/DraftsTable';

import SelfservicePageLayout from '@scenes/SelfserviceScene/layouts/Page';
import { Button, sizes } from '@components/Buttons';

export default {
  name: 'DraftScene',
  enums: {
    DraftTypes,
  },
  components: {
    SelfservicePageLayout,
    Button,

    DraftsTable,
  },
  methods: {
    createNew() {},
  },
};
</script>
